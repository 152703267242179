import styled from "styled-components";

import {ReactComponent as Logo} from "../../assets/svg/Llama Box.svg";

const HeaderContainer = styled.header`
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1.5px solid ${({theme}) => theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  column-gap: 13rem;
  row-gap: 2rem;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 500px) {
    row-gap: 1rem;
  }
`
const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  text-transform: uppercase;

  @media screen and (max-width: 500px) {
    font-size: 15px;
    line-height: 20px;
  }
`

const HeaderLogo = styled(Logo)`
  cursor: pointer;
  position: absolute;
  left: 0;

  @media screen and (max-width: 1290px) {
    position: initial;
  }
`
export const Styled = { HeaderContainer, HeaderTitle, HeaderLogo }